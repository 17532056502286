import Swiper from "swiper";
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["container", "next", "prev"];

  connect() {
    this.swiper = new Swiper(this.containerTarget, {
      navigation: {
        nextEl: this.nextTarget,
        prevEl: this.prevTarget,
      },
    });
  }
}