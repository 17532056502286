import { Application } from '@hotwired/stimulus'
import ScrollTo from 'stimulus-scroll-to'

// const application = Application.start()
// application.register('scroll-to', ScrollTo)

export default class extends ScrollTo {
  // connect() {
  //   super.connect()
  //   console.log('Do what you want here.')
  // }

  // You can set default options in this getter for all your anchors.
  get defaultOptions() {
    return {
      // offset: 100,
      behavior: 'smooth',
    }
  }
}
